import type { MetaFunction } from "@remix-run/cloudflare";
import { Outlet } from "@remix-run/react";
import { Toaster } from "~/components/ui/sonner";

export const meta: MetaFunction = () => {
    return [
        { title: "TailoredCV.ai | Your CV optimization tool" },
        {
            name: "robots",
            content: "noindex",
        },
    ];
};

export default function Layout() {
    return (
        <main className="flex min-h-screen items-center justify-center">
            <Outlet />
            <Toaster />
        </main>
    );
}
